/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/*
   * Dark Colors
   * -------------------------------------------
   */

:root {
    --ion-font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
}

* {
    --inner-padding-end: 0px;
    --inner-padding-start: 0px;
    --padding-start: 0px;
    --padding-end: 0px;
}

body {
    --ion-color-primary: #ffffff;
    --ion-color-primary-rgb: 255, 255, 255;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #ffffffbf;
    --ion-color-primary-tint: #ffffff80;
    --ion-color-secondary: #000000;
    --ion-color-secondary-rgb: 0, 0, 0;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 25;
    --ion-color-secondary-shade: #232323;
    --ion-color-secondary-tint: #111111;
    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;
    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;
    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;
    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;
    --ion-color-dark: #0a0a0a;
    --ion-color-dark-rgb: 4, 4, 4;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #111111;
    --ion-color-dark-tint: #000000;
    --ion-color-medium: #232323;
    --ion-color-medium-rgb: 14, 14, 14;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #111111;
    --ion-color-medium-tint: #0a0a0a;
    --ion-color-light: #ffffff;
    --ion-color-light-rgb: 0, 0, 0;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #ffffffbf;
    --ion-color-light-tint: #ffffff80;
}

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

.ios body {
    --ion-background-color: #0c0c0c;
    --ion-background-color-rgb: 0, 0, 0;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;
    --ion-item-background: transparent;
    --ion-card-background: #232323;
}

.ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
}

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body {
    --ion-background-color: #0c0c0c;
    --ion-background-color-rgb: 18, 18, 18;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-border-color: #222222;
    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;
    --ion-item-background: transparent;
    --ion-toolbar-background: #1f1f1f;
    --ion-tab-bar-background: #000000;
    --ion-card-background: #0c0c0c;
}

ion-button {
    --border-radius: 0.5rem;
    --background: white;
    --color: black;
    --padding-bottom: 1.5rem;
    --padding-top: 1.5rem;
}

ion-content {
    --background: transparent;
}

ion-toast {
    --background: #09bb88;
    --color: #0c0c0c;
    --button-color: #0c0c0c;
}

.no-ripple {
    --ripple-color: transparent;
    --color-hover: transparent;
}

/** 
IF WE UPDATE TO 6 we can remove this!
Fix bug from ionic https://stackoverflow.com/questions/57336796/ion-textarea-autogrow-true-on-modal-work-only-the-first-time **/

ion-textarea {
    min-height: 60px;
}

ion-textarea textarea {
    min-height: 60px;
}
