@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;600;700&display=swap");

/* JS TODO: Check if before and after exists as class helper */
.text-lines::before,
.text-lines::after {
    content: "";
    margin: 0 0.5em 0 -55%;
    @apply inline-block w-1/2 align-middle border-b border-light-700;
}

.text-lines::after {
    margin: 0 -55% 0 0.5em;
}

/* JS TODO: Change to use arbitary values.  */
.height-450 {
    height: 450px;
}

.height-250 {
    height: 250px;
}